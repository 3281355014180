<template>
  <v-tooltip top >
      <template v-slot:activator="{ on }">
          <span v-on="on">{{text}}</span>
      </template>
      <span>{{prepend}}{{hint}}{{append}}</span>
  </v-tooltip>
</template>
<script>

export default {
  
  components: {},  
  props: {
    
    text: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    prepend: {
      type: String,
      default: ''
    },
    append: {
       type: String,
       default: ''
    }
  },
  
  data () {
    return {

      
    }
  },
  
  
}
</script>