<template>
    <span>
        <v-tooltip bottom v-if="url">
            <template v-slot:activator="{ on }">
                <v-btn  
                    v-if="url"
                    :href="url" 
                    target="_blank"
                    icon
                    small
                    v-on="on"
                >
                <v-icon small>{{icon}}</v-icon>
                </v-btn>
            </template>
            <span>{{tooltip}}</span>
        </v-tooltip>
    </span>



</template>
<script>
  export default {
    props: {
        
        url: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: 'mdi-download'
        },
        tooltip: {
            type: String,
            default: '다운로드'
        },
       
    },
    
    

    data () {
      return {
        
      }
    },
    
    methods: {
        viewimages(id) {
            this.$log.warn('viewimages', id)
        },
    }
  }
</script>
