<template>
  <v-layout row>
    <v-flex xs12>
      <v-alert
          outlined
          type="warning"
          prominent
        >
          엑셀견적기 변경되었으면 이곳에 올린 후 문자나 카톡보내주세요<br />
          분석이 완료되지 않았더라도 엑셀견적기가 변경되었다면 올려주세요<br />
        </v-alert>
      <v-data-table
        sort-by
        v-model="selected"
        :headers="data.headers"
        :items="data.items"
        :items-per-page="5"
        :search="data.search"
        class="elevation-1"
        item-key="id"
        show-select        
        disable-pagination
        hide-default-footer
        no-data-text="데이터가 없습니다"
        no-results-text="`검색결과가 없습니다`"
        >
        <template v-slot:top>
          <v-toolbar flat>      
            <v-text-field
              flat
              solo
              prepend-icon="mdi-magnify"
              placeholder="결과내검색"
              v-model="data.search"
              hide-details
            
              ></v-text-field>
            <v-spacer></v-spacer>
            <v-subheader>현재:{{data.items.length}}</v-subheader>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on"  @click="onAdd">
                  <v-icon >mdi-cloud</v-icon>
                  업로드
                </v-btn>
              </template>
              <span >엑셀파일을업로드 합니다</span>
            </v-tooltip>
            <v-tooltip bottom v-if="selected.length>0">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on"  @click="onRemoveChecked">
                <v-badge 
                  class="align-self-center" 
                  color="red"
                  >
                  <template v-slot:badge>
                    <span>{{selected.length}}</span>
                  </template>
                  <v-icon v-on="on">mdi-delete</v-icon>
                </v-badge>
                </v-btn>
              </template>
              <span v-if="selected.length>0">{{selected.length}}개의 데이터를 삭제합니다</span>
              <span v-else>삭제할 데이터를 선택하세요</span>
            </v-tooltip>         
            <v-tooltip bottom >
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" ><v-icon v-on="on" @click="onDataRefresh">mdi-refresh</v-icon></v-btn>
              </template>
              <span >새로고침</span>
            </v-tooltip>   
                  
          </v-toolbar>    
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
            <span>
                <TextTooltip  :text="item.createdAt | moment('from')" :hint="item.createdAt | moment('YYYY-MM-DD HH:mm:ss')"  />
            </span>


        </template>
        <template v-slot:[`item.src`]="{ item }">
            <span>
                <open-tab-browser :url="item.src" ></open-tab-browser>
            </span>


        </template>

        
      </v-data-table>
      <v-btn block  text v-on:click="onLoadMore" v-if="!this.paging.end">{{paging.pageSize}}개 더보기</v-btn>       
    </v-flex>
  </v-layout>

</template>

<script>
import OpenTabBrowser from '@/components/OpenTabBrowser'
import TextTooltip from '@/components/Tooltip/TextTooltip'
export default {
  components: {
   OpenTabBrowser,
   TextTooltip
  },
  data () {
    return {
      selected: [],
      query: {
        firebase: this.$store.getters.firebase,
        ref: this.$store.getters.ref,
        thisRef: '',
        user: this.$store.getters.user,
        queryFirst:'',
        queryNext:'',
        refDB:''
      },
      data: {
        
        search: '',
        selected: [],
        items: [],
        totalCount: 0,
        nextData: false,
        headers: [
          {text: '업체명', value: 'companyName'},
          {text: '특징', value: 'options'},
          {text: '파일이름', value: 'imageName'},
          {text: '등록일자', value: 'createdAt'},
          {text: '다운로드', value: 'src'},

        ],
      },
      paging: {
          pageSize: 500, 
          end: false,
          loading: false
      },

      
      dialog:false,
      confireMsgHeader: {
        title: '엑셀파일삭제',
        buttonTrueText: '삭제하기',
        buttonFalseText: '취소'
      },
       
      formOptions: {
        isLoading: false,
        siteName: '',
        show1: false
      },
      
      table: 'excel'
      
     
    };
  },
  created () {
    
    this.query.refDB = this.query.firebase.firestore().collection(this.table)
    this.$log.warn(this.table,this.$store.getters.user.uid)   
        

    this.query.queryFirst =  this.query.firebase.firestore().collection(this.table)
    
    this.onDataRefresh()
    // this.getData()
  
  },
  mounted () {
   

    //
  },
  methods: {
    onAdd() {
      this.$router.push({ name: 'user.excel.add'})
    },

    onLoadMore () {
      this.$log.warn('onloadmore',this.paging)
      if (this.paging.end) {
        return
      }        
        this.paging.loading = true;
        this.handleQuestions(this.query.queryNext).then((documentSnapshots) => {
            this.paging.loading = false;            
            if (documentSnapshots.empty) {
              this.paging.end = true;
            }
        })
    },
    onDataRefresh() {      
      this.data.items=[]
      const firstPage = this.query.queryFirst.orderBy("currentTime", "desc").limit(this.paging.pageSize)     
      this.handleQuestions(firstPage);
    },
    handleEdit (item) {
     
      this.$router.push({ name: 'user.task.edit', params: { id: item.id }})
    
    },
    handleQuestions (ref) {
      const _this = this
      return new Promise((resolve, reject) => {
        ref.get().then((documentSnapshots) => {
          if (documentSnapshots.empty) {
            this.paging.end = true
            resolve(documentSnapshots)
            _this.$log.warn(reject)
          }
          documentSnapshots.forEach((doc) => {
            const theitem = doc.data()
            theitem.id = doc.id
            
            _this.data.items.push(theitem)
          })
          _this.$log.warn('data',_this.data.items)
          _this.data.nextData = true
          const lastVisible = documentSnapshots.docs[documentSnapshots.size - 1]
          if (_this.data.items.length<this.paging.pageSize) {
            _this.paging.end = true
          }
          if (!lastVisible) {
            _this.paging.end = true
            return
          }
          this.query.queryNext = this.query.queryFirst
            .startAfter(lastVisible)
            .limit(this.paging.pageSize)
            resolve(documentSnapshots)
          })
        })
    },
   
   
    getData () {
      

      this.query.firebase.database().ref(this.query.ref.mysite + '/' + this.$store.getters.user.uid)
        .limitToLast(this.query.currentPage * this.query.pageSize)
        .on('value', snapshot => {
          const items = []
      
          snapshot.forEach((doc) => {
            const theitem = doc.val()
            theitem.id = doc.key
            items.push(theitem)
          })
          items.reverse()
          this.data.items = items
          this.query.currentPage = this.query.currentPage + 1
          if (this.data.items.length === this.data.totalCount) {
            this.query.nextData = false
          }
          this.data.totalCount = this.data.items.length
          if (this.query.pageSize > this.data.totalCount) {
            this.data.nextData = false
          }
        })

    
    },

    onRemoveChecked () {
      let _this = this
      
      this.$confirm('선택한 '+  _this.selected.length + '개의 데이터를 삭제하시겠습니까?', this.confireMsgHeader).then(res => {
        if (res) {
          //window.getApp.$emit('APP_OVERLAY',{show:true,text:'삭제중입니다...'})
          const userRef = this.query.refDB
          //let deletes = {}
          
         
          _this.selected.map(function (value) {
            let index = _this.data.items.findIndex(x => x.id === value)
            
            _this.data.items.splice(index, 1)
            //_this.$log.warn('value.id',value.id)
            userRef.doc(value.id).delete()
          })

          this.doRemoteDeleteImages()
          _this.selected = []
          //_this.selected = []
          //window.getApp.$emit('APP_OVERLAY',{show:false,text:''})
          //this.$toasted.show('정상적으로 삭제되었습니다', {type: 'success'})

        }
      })
    },

    
    doRemoteDeleteImages() {
        const _this = this
        Promise.all(
            this.selected.map(item => _this.deleteImageAsPromise(item,true))        
        )
        .then(() => {
            _this.selected = []
          //window.getApp.$emit('APP_OVERLAY',{show:false,text:''})
          this.$toasted.show('정상적으로 삭제되었습니다', {type: 'success'})
        })     
        .catch((e) => {
          _this.$log.warn('error', e)
            _this.selected = []
          //window.getApp.$emit('APP_OVERLAY',{show:false,text:''})
          this.$toasted.show('정상적으로 삭제되었습니다', {type: 'success'})
        })
    },
    deleteImageAsPromise (item) {
        const _this = this
        return new Promise((resolve, reject) => {
            const storageRef = _this.query.firebase.storage().ref(item.bucket)
            storageRef.delete().then(function() {
                resolve()
            }).catch(function() {
                reject()
            })
        })
    },
    
  }
};
</script>
